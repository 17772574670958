import API from "../utils/API";

export default {
    filterOffers(data) {
        return API({
            url: "filterOffers",
            data,
            method: "POST",
            requestId: "filterOffers",
        });
    },
    subListOffers(data) {
        return API({
            url: "subSection",
            data,
            method: "POST",
            requestId: "subSection",
        });
    },

    // get all offers
    getAllOffers(data) {
        return API({
            url: "getOffers",
            data,
            method: "POST",
            requestId: "getOffers",
        });
    },

    searchOffers(data) {
        return API({
            url: "search",
            data,
            method: "POST",
            requestId: "search",
        });
    },
};
