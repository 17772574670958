<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>1DC192AD-C272-47EF-9C77-A389A98EEAB4</title>
    <g
      id="Front-Pages"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="1.0---Home---1"
        transform="translate(-316.000000, -63.000000)"
      >
        <g
          id="LEFT"
          transform="translate(143.000000, 48.000000)"
        >
          <g
            id="Cart"
            transform="translate(157.000000, 0.000000)"
          >
            <g
              id="ic_shopping-cart-2"
              transform="translate(16.000000, 15.000000)"
            >
              <g id="Frame_-_24px">
                <rect
                  id="Rectangle"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                />
              </g>
              <g
                id="Filled_Icons"
                transform="translate(2.000000, 2.000000)"
                fill="#E76300"
                fill-rule="nonzero"
              >
                <g id="Group">
                  <path
                    id="Path"
                    d="M16,0 C15.534,0 15.131,0.321 15.025,0.775 L14.05,5 L1,5 C0.691,5 0.401,5.142 0.212,5.384 C0.023,5.627 -0.045,5.944 0.029,6.242 L2.029,14.242 C2.141,14.688 2.541,15 3,15 L13,15 C13.466,15 13.869,14.678 13.975,14.225 L16.795,2 L20,2 L20,0 L16,0 Z"
                  />
                  <circle
                    id="Oval"
                    cx="4"
                    cy="18"
                    r="2"
                  />
                  <circle
                    id="Oval"
                    cx="12"
                    cy="18"
                    r="2"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CartIcon'
}
</script>
