import forms from './forms'
import home from './home'
import account from './account'
import offers from './offers'
import head from './head'

export default {
  dir: 'rtl',
  ...forms,
  ...home,
  ...account,
  ...offers,
  ...head
}
