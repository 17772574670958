export default [
  {
    path: '/payment',
    name: 'PaymentMethods',
    component: () => import(/* webpackChunkName: "about" */ '../../views/payment/index.vue'),
    meta: { isPublic: true }
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: () => import(/* webpackChunkName: "about" */ '../../views/ThankYou.vue'),
    meta: { isPublic: true }
  },

]