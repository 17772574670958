<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
  >
    <path
      id="Path"
      d="M16,12 C14.8,12 13.734,12.541 13,13.381 L7.908,10.836 C7.967,10.567 8,10.288 8,10 C8,9.712 7.967,9.434 7.908,9.163 L13,6.618 C13.734,7.458 14.8,8 16,8 C18.205,8 20,6.205 20,4 C20,1.794 18.205,0 16,0 C13.795,0 12,1.794 12,4 C12,4.287 12.033,4.566 12.092,4.836 L7,7.381 C6.266,6.542 5.2,6 4,6 C1.795,6 0,7.794 0,10 C0,12.205 1.795,14 4,14 C5.2,14 6.266,13.457 7,12.617 L12.092,15.163 C12.033,15.434 12,15.713 12,16 C12,18.205 13.795,20 16,20 C18.205,20 20,18.205 20,16 C20,13.795 18.205,12 16,12 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ShareIcon'
}
</script>
