import { createRouter, createWebHistory } from 'vue-router'

import offers from './routes/offers'
import profile from './routes/profile'
import authentication from './routes/authentication'
import payment from './routes/payment'
import staticPages from './routes/static'
import store from '@/store/index'
import i18n from '../lang/index'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home/Index.vue'),
    meta: { isPublic: true }
  },

  ...authentication,

  ...offers,

  ...profile,

  ...payment,

  ...staticPages,
]

const router = createRouter({
  // mode: 'history',
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })

  // use the language from the routing param or default language
  // let language = to.params.lang;
  // console.log('llllllllllllllang', to.params.lang)
  // if (!language) {
  //   console.log('llllllllllllllang if', language)
  //   language = 'ar'
  // } else {
  //   console.log('llllllllllllllang if', language)
  //   // set the current language for i18n.
  //   i18n.locale = language
  //   next()
  // }



  /* will put it in case i comment if condition 
  next()
  */

  /* i will comment it in case remove comment from top line */
  if (to.meta.isPublic) {
    next()
  } else if (to.meta.requireAuth) {
    if (store.state.Globals.idToken) {
      next()
    } else {
      next({ name: 'enter-phone-number' })
    }
  } else if (!store.state.Globals.idToken) {
    next()
  } else {
    next({ name: 'Home' })
  }
})

export default router
