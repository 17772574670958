import offers from '../../services/offers'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  namespaced: true,

  state: {
    allOffers: [],
    isListOffers: false
  },

  mutations: {
    GET_OFFERS(state, data) {
      state.allOffers = data
    },

    IS_LIST_OFFERS(state) {
      state.isListOffers = true
    },

    CLEAR_LIST(state) {
      state.allOffers = []
      state.isListOffers = false
    }
  },

  actions: {
    filterOffers({ commit }, payload) {
      offers
        .searchOffers(payload)
        .then((res) => {
          if (res.status == 200) {
            commit('IS_LIST_OFFERS')
            commit('GET_OFFERS', res.data.offers)
          }
        })
        .catch((err) => {
          commit('CLEAR_LIST')
          toast.info(err.response.data.data.message, { timeout: 1000 })
        })
    }
  }
}
