export default {
  account: {
    membership: 'ذهبي',
    text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
    editAccount: ' تعديل الحساب',
    startPoint: '0 نقطة',
    endPoint: '240',
    point1: '1000 نقطة',
    point2: '  300 نقطة للوصول لمستوي',
    platinum: 'البلاتينيوم',
    benefits: 'تعرف علي جميع المميزات',
    levels: 'جميع المستويات',
    saved: 'وفرت',
    egp: 'جنيه',
    spend: 'صرفت',
    month: 'هذا الشهر',
    time: 'طول الوقت',
    credit: 'رصيدك',
    text2: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما',
    addBalance: 'اضافة رصيد',
    preTransfer: ' التحويلات السابقة',
    yourPoints: 'نقاطك',
    yourMony: '1,450',
    text3: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما',
    changePoints: 'استبدل النقاط',
    getPoints: 'احصل علي نقاط',
    follow: ' تابعنا علي فيسبوك',
    title: 'بيانات الحساب',
    text4:
      ' تقدر تغير معلوماتك ، معلوماتك بنستخدمها فى التسجيل على وفرها ، شحن وتوصيل المنتجات.',
    list: ' القائمة البريدية',
    subscribe: ' جاهز توفر ؟ اشترك الآن ليصلك اخر العروض الجديدة',
    agree:
      'أود الاشتراك في القائمة البريدية للحصول علي آخر العروض والخصومات من وفرها',
    updateList: 'تحديث القائمة البريدية',

    // My addresses
    newAddress: 'أضف عنوان جديد',
    addresses: 'العناوين',
    address: ' المبنى : ٢ج ,الشارع : 199الدور : 8الشقة : 18 ,المعادي - القاهرة',
    editAddress: 'تعديل العنوان',
    deleteAddress: 'مسح العنوان',
    shippingName: 'عنوان لقيمة الكاش باك',
    date: '10/11/2020',
    price: '30',
    currency: 'LE',

    /// My Orders
    ShippingOperations: 'عمليات الشحن',
    contactus: 'تواصل معنا',
    allOrders: 'كل الطلبات',
    finishedOrders: 'الطلبات المنتهية',
    discountCoupons: ' كوبونات الخصم',
    purchaseCoupons: 'قسائم الشراء',
    howToUseCoupon: '  ازاي تستخدم الكوبون؟',
    orderStep1: '١ - زور اقرب فرع ليك',
    orderStep2: ' ٢ - اسأل موظف الفرع علي ابلكيشن وفرها',
    orderStep3: '3 - اظهر الكوبون أو الكود لتستفيد بالعرض',
    purchaseCoupons: 'ازاي تستخدم قسائم الشراء؟',

    ///My Cart
    CartContents: 'محتويات العربة',
    totalCart: 'اجمالي العربة',
    useWallet: 'استخدم من المحفظة',
    credit: '٢٠٠ جنيه',
    credit2: '٢٠٠ جنيه',
    credit3: '٢٠٠ جنيه',
    credit4: '٢٠٠ جنيه',
    title2: ' المبلغ المطلوب',
    totalCartContent: 'اجمالي محتويات العربه',
    wallet: 'المحفظة',
    expenses: 'مصاريف ادارية وضريبة',
    haseDisccount: 'لديك كود خصم؟',
    totalExpenses: 'مجموع العملية',
    totalMoney: '٤٠ جنيه',
    buyForFriend: 'شراء كهدية لصديق',
    Coupons: 'كوبونات',
    moveToFavourite: 'نقل إلي المفضلة',
    useCoupons: 'استخدم الكوبون',
    redeemCoupon: 'إسترجع الكوبون',
    addToCart: 'اضف الي العربة',
    emptyCart: 'عربة التسوق الخاصة بك فارغة',
    couponBtn: 'تحقق',
    coupon: 'كود الخصم',
    remove: 'حذف',
    removeFormFav: 'حذف من المفضلة',
    addToFav: 'نقل الي المفضلة',
    moveToCart: 'نقل إلي العربة',
    cancel: 'الغاء',
    fav: 'المفضلة',
    totalRequiredAmount: 'اجمالي المبلغ المطلوب'
  }
}
