import API from '../utils/API'

export default {
  validatePassword (data) {
    return API({
      url: 'validatePassword',
      data,
      method: 'POST',
      requestId: 'validate-password'
    })
  },

  loginByPhone (data) {
    return API({
      url: 'loginByPhone',
      data,
      method: 'POST',
      requestId: 'login'
    })
  },

  verifyAccountBySms (data) {
    return API({
      url: 'verifyAccountBySms',
      data,
      method: 'POST',
      requestId: 'verifyAccountBySms'
    })
  },

  newSenarioPasswordReferralData (data) {
    return API({
      url: 'newSenarioPasswordReferralData',
      data,
      method: 'POST',
      requestId: 'newSenarioPasswordReferralData'
    })
  },

  forgetPassword (data) {
    return API({
      url: 'forgetPassword',
      data,
      method: 'POST',
      requestId: 'forgetPassword'
    })
  },

  forgetPasswordCodeVerify (data) {
    return API({
      url: 'forgetPasswordCodeVerify',
      data,
      method: 'POST',
      requestId: 'forgetPasswordCodeVerify'
    })
  },

  resetPassword (data) {
    return API({
      url: 'resetPassword',
      data,
      method: 'POST',
      requestId: 'resetPassword'
    })
  },

  ////// old auth //////

  /// Register Service
  userRegister (data) {
    return API({
      url: 'registerUserPhoneAndPassword',
      data,
      method: 'POST',
      requestId: 'register'
    })
  },

  completeRegister (data) {
    return API({
      url: 'updateProfile',
      data,
      method: 'POST',
      requestId: 'updateProfile'
    })
  },

  getUserInfo (data) {
    return API({
      url: 'userInfo',
      data,
      method: 'POST',
      requestId: 'userInfo'
    })
  }
}
