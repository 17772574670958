export default [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '../../views/auth/Login.vue'),
    meta: { requireAuth: false }
  },

  {
    path: '/register',
    name: 'register',
    component: () => import(
      /* webpackChunkName: "Register" */ '../../views/auth/register/index.vue'
    ),
    meta: { requireAuth: false }
  },

  {
    path: '/register/enter-password',
    name: 'enter-password',
    component: () => import(
      /* webpackChunkName: "Register" */ '../../views/auth/register/EnterPassword.vue'
    ),
    meta: { requireAuth: false }
  },

  {
    path: '/forgot-password/enter-code',
    name: 'forgot-password',
    component: () => import(
      /* webpackChunkName: "Register" */ '../../views/auth/register/ForgotPassword'
    ),
    meta: { requireAuth: false }
  },

  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(
      /* webpackChunkName: "Register" */ '../../views/auth/register/ResetPassword'
    ),
    meta: { requireAuth: false }
  },

  {
    path: '/enter-phone-number',
    name: 'enter-phone-number',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(
      /* webpackChunkName: "EnterPhoneNumber" */ '../../views/auth/register/EnterPhoneNumber.vue'
    ),
    meta: { requireAuth: false }
  },
  {
    path: '/enter-phone',
    name: 'enter-phone',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(
      /* webpackChunkName: "EnterPhoneNumber" */ '../../views/auth/EnterPhone.vue'
    ),
    meta: { requireAuth: false }
  },

  {
    path: '/update-phone',
    name: 'update-phone',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(
      /* webpackChunkName: "ResetPassword" */ '../../views/update-phone.vue'
    ),
    meta: { requireAuth: true }
  },

  {
    path: '/complete-register',
    name: 'complete-register',
    component: () => import(
      /* webpackChunkName: "CompleteRegister" */ '../../views/auth/CompleteRegister.vue'
    ),
    meta: { requireAuth: true }
  }
]
