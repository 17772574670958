import md5 from 'md5'
export default function generateSecurityKey(
  dynamicEncryptedKey = {},
  fullyDynamicEncryptedKey = {}
) {
  if (dynamicEncryptedKey.hasOwnProperty('app_version')) {
    dynamicEncryptedKey.app_version = '6.0.00'
  } else {
    dynamicEncryptedKey = { ...dynamicEncryptedKey }
  }
  if (dynamicEncryptedKey.hasOwnProperty('store')) {
    dynamicEncryptedKey.store = 'PlayStore'
  } else {
    dynamicEncryptedKey = { ...dynamicEncryptedKey }
  }


  // remove some keys , Api does not calculate them!!!
  if (dynamicEncryptedKey['sub_section_id']) {
    delete dynamicEncryptedKey['sub_section_id']
  }

  if (dynamicEncryptedKey['items[0][offer_id]']) {
    delete dynamicEncryptedKey['items[0][offer_id]']
  }

  if (dynamicEncryptedKey['items[0][type_id]']) {
    delete dynamicEncryptedKey['items[0][type_id]']
  }

  if (dynamicEncryptedKey['items[0][qty]']) {
    delete dynamicEncryptedKey['items[0][qty]']
  }

  const encryptionKey = {
    ...dynamicEncryptedKey,
    ...fullyDynamicEncryptedKey,
    lang: dynamicEncryptedKey.lang ? dynamicEncryptedKey.lang : 'ar',
    // for dynamic cities filters
    city: dynamicEncryptedKey.city ? dynamicEncryptedKey.city : '3',
    limit: dynamicEncryptedKey.limit ? dynamicEncryptedKey.limit : '10'
  }
  let static_security_key = ''
  for (const key in encryptionKey) {
    static_security_key += `${key}.${encryptionKey[key]}.`
  }
  static_security_key += 'Waff6635845'

  return md5(static_security_key)
}
