<template>
  <div
    v-click-outside="hideMenu"
    class="base-dropdown d-inline-block"
    :class="{ 'w-100': takeFullWidth }"
  >
    <!--in case happen any style error in drop down add this -> m-b-10 -->
    <div class="d-flex justify-content-between align-items-center">
      <label
        :for="inputId"
        class="regular-font fs-14 font-weight-600 mb-7"
      >{{
        label
      }}</label>
      <span
        v-if="message"
        class="error-message regular-font"
      >{{
        message
      }}</span>
    </div>
    <div class="content">
      <div
        class="white-color-bg title p-x-20 cursor-pointer d-flex justify-content-between align-items-center gap-20"
        :style="{ width: fixedWidth ? fixedWidth : '100%' }"
        :class="[
          {
            'w-100': takeFullWidth,
            shadow: hasShadow,
            'has-border': hasBorder,
            'has-radius': hasRadius,
          },
          [hasError ? 'wrong-data' : ''],
          [!!value && !hasError ? 'valid-data' : ''],
        ]"
        @click="showMenu"
      >
        <span
          v-if="isMultible"
          class="medium-font black-color-text fs-14"
        >{{
          value.length === 0
            ? `${type}`
            : value.length > 1
              ? value.length + ' Values'
              : value[0].name
        }}</span>
        <span
          v-else
          class="medium-font black-color-text fs-14"
        >
          {{ value !== '' ? getSelectedOption() : `${type}` }}
        </span>

        <ArrowDown :class="{ rotate: displayDropdown }" />
      </div>

      <transition name="fadeUp">
        <div
          v-if="displayDropdown"
          class="dropdown-body white-color-bg p-y-18 p-x-16 radius-8"
        >
          <div
            v-for="option in optionsData"
            :key="option.id"
            class="option m-b-6 cursor-pointer user-select-none d-flex justify-content-start align-items-center gap-10"
            :class="{ 'p-y-6': !isMultible }"
            @click="updateSelected(option.value)"
          >
            <BaseCheckbox
              v-if="isMultible"
              input-id="subscribe"
              :value="option.value"
              :is-checked="isOptionSeletced(option.value)"
              :isLabelExist="false"
              label-color-class="main-color-text"
            />

            <span
              class="medium-font fs-14 light-gray-color-text"
              :class="{ 'is-selected': isOptionSeletced(option.value) }"
            >{{ $t(option.name) }}</span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseDropdown',

  props: {
    inputId: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: false,
      default: null
    },

    options: {
      type: [Array, Object],
      required: true
    },

    value: {
      type: [String, Array, Number],
      required: false,
      default: null
    },

    hasError: {
      type: Boolean,
      required: false,
      default: true
    },

    message: {
      type: String,
      required: false,
      default: null
    },

    isMultible: {
      type: Boolean,
      required: false,
      default: false
    },

    takeFullWidth: {
      type: Boolean,
      required: false,
      default: false
    },

    fixedWidth: {
      type: String,
      required: false,
      default: ''
    },

    hasShadow: {
      type: Boolean,
      required: false,
      default: true
    },

    hasBorder: {
      type: Boolean,
      required: false,
      default: false
    },

    hasRadius: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data () {
    return {
      displayDropdown: false,
      optionsData: [],
      type: ''
    }
  },

  watch: {
    options () {
      this.optionsData = this.options
    }
  },

  mounted () {
    this.updateDropDownName()
  },

  methods: {
    showMenu () {
      this.displayDropdown = !this.displayDropdown
    },

    hideMenu () {
      this.displayDropdown = false
    },

    updateSelected (newVal) {
      this.$emit('update-seletced', { value: newVal, id: this.inputId })
      if (!this.isMultible) this.hideMenu()
    },

    getSelectedOption () {
      return this.$t(this.options.find((el) => el.value === this.value).name)
    },

    isOptionSeletced (val) {
      if (this.isMultible) {
        const selectedValue = this.value.find((el) => el.value === val) || {}
        return !window._.isEmpty(selectedValue)
      } else {
        return this.value === val
      }
    },

    updateDropDownName () {
      if (this.inputId == 'cities') {
        this.type = this.$t('forms.chooseCity')
      } else if (this.inputId == 'latestOffers') {
        this.type = this.$t('forms.allFields')
      } else if (this.inputId == 'prices') {
        this.type = this.$t('forms.prices')
      } else if (this.inputId == 'districts') {
      } else if (this.inputId == 'paymentsTypesId') {
        this.type = this.$t('home.paymentsTypesWay')
      } else if (this.inputId == 'gender') {
      }

      this.optionsData = this.options
    }
  }
}
</script>

<style lang="scss" scoped>
@import './base-dropdown';
</style>
