import { createI18n } from 'vue-i18n'

import en from './locales/en'
import ar from './locales/ar'

const locale = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar'

const messages = {
  ar,
  en
}

const i18n = createI18n({
  locale,
  legacy: false,
  fallbackLocale: 'ar',
  silentFallbackWarn: true,
  messages
})

export default i18n
