import { createApp } from "vue";
import App from "./App.vue";

const app = createApp(App);

import router from './router/index'
import store from './store'
import i18n from './lang/index'
import Toast from './plugins/toastify'
import globalComponents from './utils/global-components'
import globalMixin from './mixins/globalMixin'

import VueGtag from "vue-gtag";
import VueClipboard from 'vue3-clipboard'


window._ = require('lodash')
window.moment = require('moment')

globalComponents.forEach((component) => {
  app.component(component.name, component)
})



import './assets/scss/main.scss'

app.config.productionTip = false

// click outside directive
app.directive('click-outside', {
  bind(el, binding, vnode) {
    document.body.addEventListener('click', (e) => {
      if (el !== e.target && !el.contains(e.target)) {
        setTimeout(() => {
          vnode.context[binding.expression](e)
        }, 10)
      }
    })
  }
})

// createApp({
//   router,
//   store,
//   i18n,
//   Toast,
//   VueClipboard,
//   computed: {
//     lang() {
//       return i18n.locale
//     }
//   },
// }).mount('#app')


app.use(i18n)
app.use(router)
app.use(VueClipboard)
app.use(store)
app.use(Toast)
app.use(VueGtag, {
  config: {
    id: "G-1FPS9KD8RP",
  }
})

app.mixin(globalMixin)

app.mount('#app')

