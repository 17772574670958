export default {
  namespaced: true,

  state: {
    isLoading: false,
    pendingRequestsIds: []
  },

  mutations: {
    SET_IS_LOADING (state, data) {
      state.isLoading = data
    },

    SET_PENDING_REQUESTS_IDS (state, data) {
      state.pendingRequestsIds = data
    }
  },

  actions: {
    setIsLoading ({ commit }, value) {
      commit('SET_IS_LOADING', value)
    },

    setPendingRequestsIds ({ commit }, value) {
      commit('SET_PENDING_REQUESTS_IDS', value)
    }
  }
}
