<template>
  <div
    class="base-tabs overflow-x-auto hidden-scroll gap-40 d-flex justify-content-start align-items-center white-color-bg p-x-18 radius-14 m-b-22"
  >
    <router-link
      v-for="(route, i) in routes"
      :key="`route-${i}`"
      :to="route.to"
      class="text-decoration-none medium-font light-gray-color-text fs-18 p-y-20 p-x-18"
    >
      {{ $t(route.title) }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',
  props: {
    routes: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import './base-tabs';
</style>
