// import * as Vue from 'vue'
// import VueRouter from 'vue-router'

// import authentication from './routes/authentication'

export default [
  {
    path: '/offers',
    name: 'offers',
    component: () => import(/* webpackChunkName: "offers" */ '../../views/Offers/Index.vue'),
    redirect: '/offers/new-offers',
    meta: { isPublic: true },

    children: [
      {
        path: 'new-offers',
        name: 'new-offers',
        component: () => import(
          /* webpackChunkName: "NewOffers" */ '../../views/Offers/NewOffers.vue'
        ),
        meta: { isPublic: true }
      },

      {
        path: 'best-offers',
        name: 'best-offers',
        component: () => import(
          /* webpackChunkName: "BestOffers" */ '../../views/Offers/BestOffers.vue'
        ),
        meta: { isPublic: true }
      }
    ]
  },

  {
    path: '/offer/:id/:offerName',
    name: 'offer-details',
    props: true,
    component: () => import(
      /* webpackChunkName: "OfferDetails" */ '../../views/Offers/OfferDetails.vue'
    ),
    meta: { isPublic: true }
  }
]
