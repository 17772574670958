import { createApp } from "vue";
const app = createApp();

import Toast from "vue-toastification";
import { POSITION } from "vue-toastification";

import 'vue-toastification/dist/index.css'


const toastOptions = {
  //position: POSITION.BOTTOM_CENTER,
  transition: "Vue-Toastification__bounce",
  maxToasts: 1,
  newestOnTop: true,
  timeout: 2500
}

app.use(Toast, toastOptions)

export default Toast
