<template>
  <!-- Show Code Coupon-->
  <BaseModal
    v-if="openModal"
    :title="$t('account.newAddress')"
    @close="hideModalPopup()"
  >
    <div>
      <div
        class="use-coupon align-text"
        v-html="couponData.html_data"
      />

      <div
        class="coupon-status d-flex justify-content-between align-items-center p-x-20 p-y-12 m-y-20"
      >
        <span>{{ $t('home.status') }} :
          <span class="status-text">{{ coupon_status }}</span></span>

        <span>
          {{ $t('home.validation') }} :
          <span class="expire-date">{{ expire_date }}</span>
        </span>
      </div>

      <h4>{{ $t('home.couponNumber') }}</h4>

      <div class="voucher_sn">
        <div
          v-for="coupon in couponData.coupons"
          :key="coupon.purchase_id"
        >
          <div class="m-y-10">
            <div class="coupon-number">
              <h2
                id="code"
                class="p-t-4"
              >
                {{ coupon.voucher_sn }}
              </h2>

              <div class="d-flex align-items-center">
                <div
                  v-clipboard:copy="coupon.voucher_sn"
                  v-clipboard:success="onCopy"
                  type="button"
                >
                  <CopyIcon class="copy-icon" />
                </div>

                <!-- <ShareIcon class="share-icon" /> -->

                <div @click="creatQRCode()">
                  <QRIcon class="qr-icon" />
                </div>

                <QRCODE
                  :openQRModal="showQR"
                  :value="coupon.voucher_sn"
                  @closeQRModal="closeQRModal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '../base-modal/Index.vue'
import CopyIcon from '../../../components/icons/CopyIcon.vue'
import ShareIcon from '../../../components/icons/ShareIcon.vue'
import QRIcon from '../../../components/icons/QRIcon.vue'
import QRCODE from './QRCode.vue'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  name: 'ShowCodemodal',
  components: { BaseModal, CopyIcon, ShareIcon, QRCODE, QRIcon },

  props: {
    openModal: {
      type: Boolean
    },

    couponData: {
      type: Object
    },

    coupon_status: {
      type: String
    },

    expire_date: {
      type: String
    }
  },

  data () {
    return {
      showQR: false
    }
  },

  methods: {
    hideModalPopup () {
      this.$emit('closeModal', this.openModal)
    },

    onCopy () {
      toast.success(this.$t('home.success'))
    },

    creatQRCode () {
      this.showQR = true
    },

    closeQRModal () {
      this.showQR = false
    }
  }
}
</script>

<style scoped>
:deep(.use-coupon) {
  padding: 0 20px;
}
:deep(.use-coupon h3) {
  font-size: 16px;
}

:deep(.use-coupon ol) {
  padding: 5px 15px;
}
:deep(.use-coupon ol li) {
  font-size: 14px;
  color: #34353a;
  padding: 4px 0;
}

:deep(.coupon-status) {
  color: #34353a;
  border-top: 1px solid rgba(143, 144, 157, 0.2);
  border-bottom: 1px solid rgba(143, 144, 157, 0.2);
}

:deep(.status-text) {
  color: green;
}

:deep(.expire-date) {
  color: black;
}

:deep(.coupon-number) {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 70%;
  margin: 10px auto;
  background-color: rgba(231, 99, 0, 0.2) !important;
  color: #e76300;
  padding: 10px;
}

.voucher_sn {
  height: 200px;
  overflow-y: scroll;
}

.copy-icon {
  width: 30px;
  height: 30px;
  fill: #979299;
  cursor: pointer;
  margin-top: 12px;
}

.share-icon {
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  fill: #979299;
  cursor: pointer;
}

.qr-icon {
  width: 30px;
  height: 30px;
  fill: #979299;
  cursor: pointer;
  margin-top: 14px;
}
</style>
