import auth from '../../services/auth'
import router from '@/router/index'
import { useToast } from 'vue-toastification'
const toast = useToast()


export default {
  namespaced: true,
  state: {
    userPhone: '',
    reset_token: '',
    idToken: '',
    userInfo: {}
  },

  getters: {
    returnUserPhone(state) {
      return state.userPhone
    },

    userPhone(state) {
      return state.userPhone
    },

    authPassword(state) {
      return state.showAuthPassword
    },

    userInfo(state) {
      return state.userInfo
    },

    returnUserToken(state) {
      return state.idToken
    },

    checkUserLogin(state) {
      return !!state.idToken
    }
  },

  mutations: {
    Save_User_Phone(state, userPhone) {
      state.userPhone = userPhone
    },

    Reset_Token(state, payload) {
      state.reset_token = payload.reset_token
    },

    Set_User_Data(state, userData) {
      state.idToken = userData.token
      localStorage.setItem('token', userData.token)
    },

    Get_User_Data(state, userData) {
      state.userInfo = userData
    },

    clearAuthData(state) {
      state.idToken = null
      state.userInfo = null
      state.userPhone = null
      state.reset_token = null
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('userPhone')
      localStorage.removeItem('updateProfileData')
    }
  },

  actions: {
    loginByPhone({ commit }, payload) {
      auth
        .loginByPhone(payload)
        .then((res) => {
          if (res.status == 200) {
            commit('Save_User_Phone', payload.phone)
            router.push({ name: 'login' })
          }
        })
        .catch((err) => {
          if (err.response.status == 301) {
            commit('Save_User_Phone', payload.phone)
            router.push({ name: 'register' })
            toast.success(err.response.data.data.message)
          }
        })
    },

    reSendCode({ commit }, payload) {
      auth
        .loginByPhone(payload)
        .then((res) => {
          if (res.status == 200) {
            commit('Save_User_Phone', payload.phone)
          }
        })
        .catch((err) => {
          // if (err.response.status == 301) {
          //   commit('Save_User_Phone', payload.phone)
          //   router.push({ name: 'register' })
          //   toast.success(err.response.data.data.message)
          // }
        })
    },

    verifyAccountBySms({ commit }, payload) {
      auth
        .verifyAccountBySms(payload)
        .then((res) => {
          if (res.status == 200) {
            router.push({ name: 'enter-password' })
          }
        })
        .catch((err) => {
          toast.error(err.response.data.data.message)
        })
    },

    enterRegisterPassword({ commit }, payload) {
      auth
        .newSenarioPasswordReferralData(payload)
        .then((res) => {
          if (res.status == 200) {
            toast.success(res.data.message)
            router.push({ name: 'enter-phone-number' })
          }
        })
        .catch((err) => {
          toast.error(err.response.data.data.message)
        })
    },

    login({ commit, dispatch }, payload) {
      auth
        .validatePassword(payload)
        .then((res) => {
          if (res.status == 200) {
            router.push('/')
            commit('Set_User_Data', res.data)
            dispatch('getUserInfo')
          } else {
            toast.error(res.data.message)
          }
        })
        .catch((err) => {
          toast.error(err.response.data.data.message)
        })
    },

    ///// forgot password scenario
    forgotPassword({ commit }, payload) {
      commit('Save_User_Phone', payload.phone) -
        auth
          .forgetPassword(payload)
          .then((res) => {
            if (res.status == 200) {
              router.push({ name: 'forgot-password' })
              toast.success(res.data.message)
            }
          })
          .catch((err) => {
            toast.error(err.response.data.data.message)
          })
    },

    forgetPasswordCodeVerify({ commit }, payload) {
      auth
        .forgetPasswordCodeVerify(payload)
        .then((res) => {
          if (res.status == 200) {
            commit('Reset_Token', res.data)
            router.push({ name: 'reset-password' })
          }
        })
        .catch((err) => {
          toast.error(err.response.data.data.message)
        })
    },

    resetPassword({ commit }, payload) {
      auth
        .resetPassword(payload)
        .then((res) => {
          if (res.status == 200) {
            toast.success(res.data.message)
            router.push({ name: 'login' })
          }
        })
        .catch((err) => {
          toast.error(err.response.data.data.message)
        })
    },

    getUserInfo({ commit, getters }) {
      const payload = {
        token: getters.returnUserToken,
        app_version: '',
        store: ''
      }

      auth.getUserInfo(payload).then((res) => {
        // localStorage.setItem('userInfo', JSON.stringify(res.data.user))
        commit('Save_User_Phone', res.data.user.users_mobile)
        commit('Get_User_Data', res.data.user)
      })
    },

    ////updateProfile
    completeUserRegister({ commit, dispatch }, data) {
      auth
        .completeRegister(data)
        .then((res) => {
          if (res.status == 200) {
            dispatch('getUserInfo')
            toast.success(res.data.message)
          }
        })
        .catch((err) => {
          if (err.response.status == 301) {
            commit('Save_User_Phone', data.mobile)
            toast.success(err.response.data.data.message)
            router.push('/update-phone')
          } else {
            toast.error(err.data.errors[0])
          }
        })
    },

    verifyAccountAfterUpdatePhone({ dispatch }, payload) {
      auth
        .verifyAccountBySms(payload)
        .then((res) => {
          if (res.status == 200) {
            dispatch(
              'completeUserRegister',
              JSON.parse(localStorage.getItem('updateProfileData'))
            )
            router.push('/profile/settings/account-details')
          } else {
            toast.error(err.response.data.data.message)
          }
        })
        .catch((err) => {
          toast.error(err.response.data.data.message)
        })
    },

    ///logout
    logout({ commit }) {
      commit('clearAuthData')
      router.replace('/').catch(() => { })
    }
  }
}
