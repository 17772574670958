import BaseButton from '@/components/partials/base-button/Index.vue'
import BaseDropdown from '@/components/partials/base-dropdown/Index.vue'
import BaseTabs from '@/components/partials/base-tabs/Index.vue'
import BaseOfferCard from '@/components/partials/base-offer-card/Index.vue'
import BaseOrderCard from '@/components/partials/base-order-card/Index.vue'
import BaseCheckbox from '@/components/partials/base-checkbox/Index.vue'
import BaseTextInput from '@/components/partials/base-text-input/Index.vue'

// icons
import HomeIcon from '@/components/icons/HomeIcon.vue'
import CartIcon from '@/components/icons/CartIcon.vue'
import UserIcon from '@/components/icons/UserIcon.vue'
import ArrowDown from '@/components/icons/ArrowDown.vue'
import HeartLineIcon from '../components/icons/HeartLineIcon.vue'
import OrdersIcon from '../components/icons/OrdersIcon.vue'
import AccountIcon from '../components/icons/AccountIcon.vue'
import ShareIcon from '../components/icons/ShareIcon.vue'
import LogoutIcon from '../components/icons/LogoutIcon.vue'
import settingsIcon from '../components/icons/settingsIcon.vue'
import CopyIcon from '../components/icons/CopyIcon.vue'
import QRIcon from '../components/icons/QRIcon.vue'




export default [
  BaseTextInput,
  BaseCheckbox,
  BaseButton,
  HomeIcon,
  HeartLineIcon,
  OrdersIcon,
  AccountIcon,
  ShareIcon,
  LogoutIcon,
  settingsIcon,
  CartIcon,
  UserIcon,
  ArrowDown,
  BaseOfferCard,
  BaseOrderCard,
  BaseTabs,
  BaseDropdown,
  CopyIcon,
  QRIcon
]
