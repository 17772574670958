<template>
  <div
    :singleCardDetails="singleCardDetails"
    class="base-offer-card"
  >
    <div class="cover-container">
      <!-- <div
        class="discount d-flex justify-content-between align-items-center bold-font main-color-bg white-color-text"
      >
        <span class="bold-font">{{ $t('offers.discount') }}</span>
        <span class="bold-font">{{ singleCardInfo.offer_discount }}%</span>
      </div> -->

      <div class="offer-image-link">
        <router-link
          :to="{
            name: 'offer-details',
            params: {
              id: singleCardInfo.offer_id,
              offerName: offerNameParam,
            },
          }"
        >
          <img
            class="d-block"
            :src="singleCardInfo.offer_image"
            :alt="singleCardInfo.offer_name"
          >
        </router-link>
      </div>
    </div>

    <div class="content white-color-bg">
      <div class="content-container">
        <div class="offer-place d-flex justify-content-between align-items-end">
          <div class="place-name d-flex justify-content-start align-items-end">
            <div class="img-container">
              <img
                :src="singleCardInfo.partners.part_logo"
                alt=""
              >
            </div>

            <span class="medium-font light-gray-color-text line-height-1">{{
              singleCardInfo.partners.part_name
            }}</span>
          </div>
        </div>

        <div
          class="offer-title d-flex justify-content-between align-items-center"
        >
          <h4 class="text-right my-2">
            {{ singleCardInfo.offer_name }}
          </h4>

          <router-link
            :to="{
              name: 'offer-details',
              params: {
                id: singleCardInfo.offer_id,
                offerName: offerNameParam,
              },
            }"
            class="bold-font dark-gray-color-text"
          >
            {{ singleCardInfo.name }}
          </router-link>

          <div class="rate d-flex justify-content-end align-items-center">
            <StarIcon />
            <span class="secondary-font gold-color-text">{{
              Math.round(singleCardInfo.rate * 10) / 10
            }}</span>
          </div>
        </div>

        <div
          class="offer-price d-flex justify-content-between align-items-center"
        >
          <div class="price d-flex justify-content-start align-items-center">
            <span
              class="main-color-bg white-color-text medium-font"
            >{{ singleCardInfo.actual_value }} {{ $t('account.egp') }}</span>
            <span
              class="dark-gray-color-text medium-font"
            >{{ singleCardInfo.offer_value }} {{ $t('account.egp') }}</span>
          </div>

          <div
            class="add-to-favourite"
            :class="{ redHeart: isFav == singleCardInfo.status_fav_count }"
            @click="toggleWishList(singleCardInfo.offer_id)"
          >
            <HeartLineIcon />
          </div>
        </div>
      </div>
    </div>

    <!-- Auth modal -->
    <AuthModal :openModal="openModal" />
  </div>
</template>

<script>
import StarIcon from '../../icons/StarIcon'
import HeartLineIcon from '../../icons/HeartLineIcon'
import AuthModal from '../../partials/auth-modal/index.vue'
import ToggleWishList from '../../../services/profile'

export default {
  name: 'BaseOfferCard',
  components: { HeartLineIcon, StarIcon, AuthModal },
  props: {
    singleCardInfo: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      singleCardDetails: [],
      isFav: true,
      openModal: false,
      toggleFavPayload: {},
      offerPath: {}
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters['Globals/checkUserLogin']
    },

    offerNameParam () {
      const offerName = this.singleCardInfo.offer_name.replace(
        /[ "" 25%]/g,
        '-'
      )
      return offerName
    }
  },

  // send offer id to parent which can be used to navigate to inner offer
  methods: {
    // sendOfferId () {
    //   this.$emit(
    //     'setIdInConsole',
    //     this.singleCardInfo.offer_id,
    //     this.singleCardInfo.offer_name
    //   )
    // },

    toggleWishList (setId) {
      if (!this.isLoggedIn) {
        this.openModal = true
        this.toggleFavPayload = {
          app_version: '',
          store: '',
          offer_id: setId
        }
      } else {
        this.isFav = !this.isFav
        this.toggleFavPayload = {
          app_version: '',
          store: '',
          token: this.getUserToken,
          offer_id: setId
        }
      }

      ToggleWishList.toggleWishList(this.toggleFavPayload)
        .then(() => {})
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import './base-offer-card';
</style>
