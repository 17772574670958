<template>
  <BaseModal
    v-if="openModal"
    :title="$t('account.newAddress')"
    @close="hideModalPopup"
  >
    <h3 class="text-center mb-4">
      {{ $t('forms.welcome') }}
    </h3>
    <BaseButton
      color-class="white-color-text m-b-12"
      bg-color-class="main-color-bg"
      :text="$t('forms.login')"
      is-route
      is-medium
      :route-url="'enter-phone-number'"
      fixed-width="180px"
    />

    <BaseButton
      color-class="white-color-text"
      bg-color-class="black-color-bg"
      :text="$t('forms.newAccount')"
      is-route
      is-medium
      :route-url="'enter-phone-number'"
      fixed-width="180px"
    />
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/partials/base-modal/Index'

export default {
  name: 'AuthModal',
  components: { BaseModal },

  props: {
    openModal: {
      type: Boolean
    }
  },
  methods: {
    hideModalPopup () {
      this.$emit('closeModal', this.openModal)
    }
  }
}
</script>
