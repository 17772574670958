<template>
  <router-link
    v-if="isRoute"
    :to="routeUrl"
    class="base-button radius-24 medium-font"
    :class="[
      bgColorClass,
      colorClass,
      isSmall ? 'is-small' : '',
      isMedium ? 'is-medium' : '',
    ]"
    :style="{ minWidth: !!fixedWidth ? fixedWidth : '100%' }"
  >
    {{ $t(text) }}
  </router-link>
  <button
    v-else
    class="base-button radius-24 medium-font"
    :class="[
      bgColorClass,
      colorClass,
      isSmall ? 'is-small' : '',
      isMedium ? 'is-medium' : '',
    ]"
    :style="{ minWidth: !!fixedWidth ? fixedWidth : '100%' }"
    @click="$emit('action')"
  >
    {{ $t(text) }}
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    isRoute: {
      type: Boolean,
      required: false,
      default: false
    },
    routeUrl: {
      type: [String, Object],
      required: false,
      default: '/'
    },
    bgColorClass: {
      type: String,
      required: false,
      default: 'black-color-bg'
    },
    colorClass: {
      type: String,
      required: false,
      default: 'white-color-text'
    },
    text: {
      type: String,
      required: true
    },
    fixedWidth: {
      type: String,
      required: false,
      default: ''
    },
    minWidth: {
      type: String,
      required: false,
      default: ''
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    isMedium: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.base-button {
  height: 50px;
  min-width: 200px;
  font-size: 15px;
  cursor: pointer;
  border: none;
  outline: none;
  text-decoration: none;
  display: grid;
  place-items: center;

  &.is-medium {
    font-size: 14px;
    height: 40px;
  }

  &.is-small {
    font-size: 12px;
    height: 30px;
  }
}
</style>
