import API from '../utils/API'

export default {
  GetListPurchases(data) {
    return API({
      url: 'listPurchases',
      data,
      method: 'POST',
      requestId: 'listPurchases'
    })
  },

  orderCouponData(data) {
    return API({
      url: 'listCoupons',
      data,
      method: 'POST',
      requestId: 'listCoupons'
    })
  },

  orderCouponRefundData(data) {
    return API({
      url: 'purchaseCouponsRefundWays',
      data,
      method: 'POST',
      requestId: 'purchaseCouponsRefundWays'
    })
  },

  doRefundByCouponIds(data) {
    return API({
      url: 'doRefundByCouponIds',
      data,
      method: 'POST',
      requestId: 'doRefundByCouponIds'
    })
  },


  getWishList(data) {
    return API({
      url: 'getMyWishList',
      data,
      method: 'POST',
      requestId: 'getMyWishList'
    })
  },

  toggleWishList(data) {
    return API({
      url: 'toMyWishList',
      data,
      method: 'POST',
      requestId: 'toMyWishList'
    })
  }
}
