export default {
  home: {
    title: 'اهلا بك',
    home: 'الرئيسية',
    offers: 'العروض',
    myAccount: 'حسابي',
    myOrders: 'طلباتي',
    showOfferCoupon: 'اظهر كود العرض',
    couponNumber: 'رقم الكوبون',
    status: 'الحالة',
    validation: 'صالح حتي',
    success: 'تم النسخ بنجاح',
    refundCoupon: 'استرجاع الكوبون',
    paymentWay: 'طريقة الاسترجاع',
    refundReason: 'سبب الاسترجاع',
    codeFawry: 'كود فوري: ',
    codeAman: 'كود أمان:',
    paymentsTypesWay: 'ادخل طريقة الاسترجاع',
    paymentsTypesWayTitle: 'استرجاع الكوبون',
    savedOffers: 'المفضلة',
    settings: 'الإعدادات',
    invite: 'أدعو صديقك',
    singOut: 'تسجيل الخروج',
    signIn: 'تسجيل دخول',
    newAccount: 'تسجيل حساب جديد',
    saveForToday: 'ماذا تريد ان توفر اليوم؟',
    findNearestOffers: 'اكتشف العروض القريبة منك دلوقتي',
    download: 'دلوقتي تقدر تحمل تطبيق وفرها على الأندرويد والآيفون',
    sendSMS: 'عن طريق SMS ارسل رسالة علي',
    ready: 'جاهز لتبدأ توفير اليوم؟',
    subscribe: 'اشترك الآن ليصلك كل جديد',
    subscribeNow: 'استرك الان',
    male: 'ذكر',
    female: 'أنثي',
    footerText:
      ' هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ماسيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقراتفي الصفحة التي يقرأها',
    about: 'عن وفرها',
    who: 'من نحن',
    privacy: 'سياسة الخصوصية',
    contactUs: ' تواصل معنا',
    use: ' سياسة الإستخدام',
    knowMore: 'إعرف المزيد',
    howToBuy: 'إزاي أشتري',
    suggestion: 'اقتراحات ',
    howToPay: ' إزاي أدفع',
    conditions: 'شروط الاستخدام',
    ar: 'العربية',
    en: 'الأنجليزية',
    or: 'أو',
    seeOffer: 'تصفح العرض',
    thanks: 'شكراً لك',
    openAllOffers: 'تصفج جميع العروض',
    LoadMoreOffers: 'تصفح المزيد',
    noOffersToShow: 'لا يوجد نتائج للعرض',
    removeFromFavTitle: 'هل تريد حذف هذا العرض',
    moveToFavTitle: ' هل تريد حذف هذا العرض ونقله للعروض للمفضلة',
    orderId: 'رقم الطلب:'

  }
}
