<template>
  <!-- Show Code Coupon-->
  <BaseModal
    v-if="openRefundModal"
    @close="hideModalPopup()"
  >
    <h3 class="title align-text m-b-20">
      {{ $t('home.paymentsTypesWayTitle') }}
    </h3>
    <h5 class="align-text m-x-12">
      {{ $t('home.paymentWay') }}
    </h5>

    <form @submit.prevent="refundCouponMethod()">
      <div class="m-x-18">
        <BaseDropdown
          takeFullWidth
          input-id="paymentsTypesId"
          :options="refund_payment_types"
          :value="form.paymentTypes"
          @update-seletced="updateSelectedPaymentType"
        />
      </div>

      <div
        v-for="item in couponRefundData.purchases"
        :key="item.purchase_id"
        class="m-t-12 m-x-12 align-text"
      >
        <div v-if="item.purchase_id === purchaseId">
          <div class="purchases-body">
            <h3>{{ item.partner_name }}</h3>
            <span class="type-name">{{ item.type_name }}</span>

            <div
              v-for="coupon in item.coupons"
              :key="coupon.coupon_id"
            >
              <div class="coupon-voucher m-t-8">
                <label
                  :for="coupon.coupon_id"
                  class="d-flex justify-content-start align-items-center"
                >
                  <input
                    :id="coupon.coupon_id"
                    v-model="selectedValue"
                    name="PaymentCard"
                    :value="coupon.coupon_id"
                    type="radio"
                    hidden
                    @change="getPaymentMethodId(coupon.coupon_id)"
                  >
                  <span class="radio m-r-8" />
                  <span class="light-gray-color-text medium-font">{{
                    coupon.voucher_sn
                  }}</span>
                </label>
              </div>
            </div>
          </div>

          <template>
            <h5 class="m-t-30">
              {{ $t('home.refundReason') }}
            </h5>
            <textarea
              id="message"
              v-model="form.message"
              :placeholder="$t('home.refundReason')"
              class="refund-reason medium-font m-t-10 m-b-18 w-100 radius-26"
              name="message"
              rows="4"
              cols="30"
              @input.nativ="validateData"
            />
          </template>
        </div>
      </div>
      <BaseButton
        v-if="!isRefundSuccess"
        class="d-flex justify-content-center m-auto"
        bgColorClass="dark-gray-color-bg-with-opacity"
        colorClass="dark-gray-color-text"
        fixedWidth="160px"
        :is-medium="true"
        :text="$t('home.paymentsTypesWayTitle')"
      />

      <BaseButton
        v-if="isRefundSuccess"
        class="d-flex justify-content-center m-auto"
        bgColorClass="main-color-bg"
        colorClass="white-color-text"
        fixedWidth="160px"
        :is-medium="true"
        :text="$t('home.paymentsTypesWayTitle')"
      />
    </form>
  </BaseModal>
</template>

<script>
import BaseModal from '../base-modal/Index.vue'
import BaseDropdown from '@/components/partials/base-dropdown/Index.vue'
import Form from '@/services/Form'
import DoRefundByCouponIds from '../../../services/profile'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  name: 'RefundCodemodal',
  components: { BaseModal, BaseDropdown },

  props: {
    openRefundModal: {
      type: Boolean
    },

    couponRefundData: {
      type: [Object, Array]
    },

    refund_payment_types: {
      type: Array
    },

    orderId: {
      type: Number
    },

    purchaseId: {
      type: Number
    }
  },

  data () {
    return {
      selectedValue: '',
      isRefundSuccess: false,
      form: new Form({
        paymentTypes: '',
        coupon_id: '',
        message: ''
      })
    }
  },

  methods: {
    hideModalPopup () {
      this.$emit('closeRefundModal', this.openModal)
    },

    updateSelectedPaymentType ({ id, value }) {
      this.form.paymentTypes = value
      this.validateData()
    },

    getPaymentMethodId (id) {
      this.form.coupon_id = id
      this.validateData()
    },

    validateData () {
      if (this.form.paymentTypes && this.form.coupon_id && this.form.message) {
        this.isRefundSuccess = true
      }
    },

    refundCouponMethod () {
      this.openRefundModal = true
      const payload = {
        token: this.getUserToken,
        app_version: '',
        store: '',
        order_id: this.orderId,
        // 'refund_coupon_ids[0]': this.coupon_id,
        refund_reason: this.form.message,
        refund_payment_method_id: this.form.paymentTypes
      }

      const refundData = this.couponRefundData.purchases
      for (const item in refundData) {
        payload[`refund_purchases[${item}][purchase_id]`] =
          refundData[item].purchase_id

        for (const index in refundData[item].coupons) {
          payload[`refund_purchases[${item}][coupons][${index}][coupon_id]`] =
            refundData[item].coupons[index].coupon_id

          payload[`refund_purchases[${item}][coupons][${index}][voucher_sn]`] =
            refundData[item].coupons[index].voucher_sn
        }
      }

      DoRefundByCouponIds.doRefundByCouponIds(payload)
        .then((res) => {
          toast.success(res.data.message)
          this.openRefundModal = false
        })
        .catch((err) => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.type-name {
  color: #979299;
}
.coupon-voucher {
  cursor: pointer;
  user-select: none;

  input {
    &:checked + span {
      border-color: $main-color;

      & + span {
        color: $main-color;
      }

      &:before {
        opacity: 1;
      }
    }
  }

  span {
    cursor: pointer;
    font-size: 15px;
    transition: color 0.3s ease-in-out;

    &:first-of-type {
      display: inline-grid;
      place-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid $light-gray-color;
      position: relative;
      transition: border-color 0.3s ease-in-out;

      &:before {
        content: '';
        width: 12px;
        height: 12px;
        background-color: $main-color;
        position: absolute;
        border-radius: 50%;
        display: block;
        text-align: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
    }
  }
}

textarea {
  resize: none;
}

.refund-reason {
  border: none;
  box-shadow: 0 4px 14px 0 rgb(5 5 5 / 20%);
  padding: 10px 20px;

  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
}
</style>
