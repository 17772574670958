import staticPages from '../../services/staticPages'
import { useToast } from 'vue-toastification'
const toast = useToast()


export default {
  namespaced: true,

  state: {
    content: '',
    title: '',
    text: '',
    paymentMethods: [],
    page_title: ''
  },

  mutations: {
    SET_CONTENT(state, data) {
      state.content = data.data.page
      state.title = data.data.page_title
    },

    SET_BUY(state, data) {
      state.text = data.data.html_page
      state.title = data.data.page_title
    },

    SET_BAY(state, data) {
      state.page_title = data.data.page_title
      state.paymentMethods = data.data.page
    }
  },

  actions: {
    getPolicy({ commit }) {
      staticPages
        .getPrivacyPolicy()
        .then((res) => {
          commit('SET_CONTENT', res.data)
        })
        .catch((err) => { })
    },

    usePolicy({ commit }) {
      staticPages
        .usePolicy()
        .then((res) => {
          commit('SET_CONTENT', res.data)
        })
        .catch((err) => { })
    },

    aboutUs({ commit }) {
      staticPages
        .aboutUs()
        .then((res) => {
          commit('SET_CONTENT', res.data)
        })
        .catch((err) => { })
    },

    howToBuy({ commit }) {
      staticPages
        .howToBuy()
        .then((res) => {
          commit('SET_BUY', res.data)
        })
        .catch((err) => { })
    },

    howToPay({ commit }) {
      staticPages
        .howToPay()
        .then((res) => {
          commit('SET_BAY', res.data)
        })
        .catch((err) => { })
    },

    contactUs({ commit }, payload) {
      staticPages
        .contactUs(payload)
        .then((res) => {
          if (res.status == 200) {
            toast.success('Send Successfully')
          }
        })
        .catch((err) => { })
    }
  }
}
