import vue from 'vue'
import isEmailValid from '@/utils/isValidEmail'
import isPasswordValid from '@/utils/isPasswordValid'
import isPhoneValid from '@/utils/isPhoneValid'

export default {
  computed: {
    getUserToken() {
      return this.$store.getters['Globals/returnUserToken']
    },
    userData() {
      return this.$store.getters['Globals/userInfo']
    },
    isLoggedIn() {
      return this.$store.getters['Globals/checkUserLogin']
    },

    getUserIp() {
      return localStorage.getItem('my_ip')
    }
  },
  methods: {
    /// update input value
    getLoginData(val) {
      this.form[val.id] = val.value
    },

    ///update select value
    updateSeletced({ value }) {
      this.form.gender = value
    },

    // Validate Email
    validateEmail() {
      if (!isEmailValid(this.form.email)) {
        this.errors.recordField({
          email: this.$t('forms.validEmail')
        })
      }
    },

    //Validate Password
    validatePassword() {
      ['password', 'confirmPassword'].forEach((el) => {
        if (!isPasswordValid) {
          this.errors.recordField({
            [el]: this.$t('forms.validPassword')
          })
        }
      })
    },

    //Validate  Phone Number
    validatePhoneNumber() {
      ['phone', 'walletNumber'].forEach(el => {
        if (isNaN(+this.form[el]) || !isPhoneValid(this.form[el])) {
          this.errors.recordField({
            [el]: this.$t("forms.validPhone")
          });
        }
      });
    }
  }

}

