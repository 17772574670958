<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 19"
  >
    <path
      id="Path"
      d="M13,0 L2,0 C0.897,0 0,0.898 0,2 L0,9 L7.59,9 L5.293,6.708 L6.706,5.292 L11.414,9.991 L6.708,14.706 L5.292,13.294 L7.582,11 L0,11 L0,18 C0,19.103 0.897,20 2,20 L13,20 C14.103,20 15,19.103 15,18 L15,2 C15,0.898 14.103,0 13,0 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'LogoutIcon'
}
</script>
