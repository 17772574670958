export default {
  home: {
    title: 'Welcome',
    home: 'Home',
    offers: 'Offers',
    myAccount: 'My Account',
    myOrders: 'My Orders',
    couponNumber: 'Coupon Number',
    refundCoupon: 'Refund Coupon',
    success: 'Copied Succesfuly',
    paymentsTypesWay: 'Choose Refund Way',
    paymentWay: 'Payment Way',
    refundReason: 'Refund Reason',
    codeFawry: 'Code Fawry: ',
    codeAman: 'Code Aman:',
    paymentsTypesWayTitle: 'Refund Coupon',
    status: 'Status',
    validation: 'Validation',
    showOfferCoupon: 'Show Coupon',
    savedOffers: 'Favorites',
    settings: 'Settings',
    invite: 'Invite Your Friend',
    singOut: 'Sign Out',
    signIn: ' Sing In',
    newAccount: 'New Account',
    saveForToday: ' What do you want to save for today ?',
    findNearestOffers: ' Discover your nearest offers',
    download: 'Now you can download waffarha app on android and apple',
    sendSMS: ' Send SMS',
    ready: 'Ready to start today save ',
    subscribe: 'subscribe to get updates',
    subscribeNow: ' Subscribe Now',
    male: 'male',
    female: 'female',
    footerText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi laoreet magna vel lorem iaculis, nec rutrum tortor consequat. Etiam finibus dui a tortor consequat, eget scelerisque nisl suscipit. Maecenas condimentum nunc nisl, vestibulum iaculis nisi molestie porttitor. Curabitur in mollis odio, eget ultricies ipsum',
    about: 'about waffarha',
    who: 'Who We Are',
    privacy: ' Privacy policy',
    contactUs: 'Contacs us',
    use: 'Use policy',
    knowMore: ' Know more',
    howToBuy: 'How To Buy',
    suggestion: 'Suggestion',
    howToPay: 'How To Pay',
    conditions: ' conditions',
    ar: 'Arabic',
    en: 'English',
    or: 'OR',
    seeOffer: 'see offer',
    thanks: 'Thank You',
    openAllOffers: 'All Offerss',
    LoadMoreOffers: 'Loade More',
    noOffersToShow: 'there are no products to show',
    removeFromFavTitle: 'Want To Remove This Offer',
    moveToFavTitle: 'Want to remove this offer and move to favourite',
    orderId: 'Order Id:'
  }
}
