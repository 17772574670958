import locations from '../../services/locations'

export default {
  namespaced: true,

  state: () => ({
    allCities: [],
    allDistricts: []
  }),

  mutations: {
    set_Cities(state, data) {
      state.allCities = data.map((el) => {
        return {
          id: el.place_id,
          name: el.place_name,
          value: el.place_id
        }
      })
    },

    Set_Districts(state, data) {
      state.allDistricts = data.map((el) => {
        return {
          id: el.location_id,
          name: el.location_name,
          value: el.location_id
        }
      })
    }
  },

  getters: {
    cities(state) {
      return state.allCities
    },

    districts(state) {
      return state.allDistricts
    }
  },

  actions: {
    /// Get Cities
    getAllCountries(context) {
      locations
        .getCities()
        .then((res) => {
          context.commit('set_Cities', res.data.cities)
        })
        .catch((err) => { })
    },

    //Get Districts
    getAllDistricts(context, payload) {
      locations
        .getDistricts(payload)
        .then((res) => {
          context.commit('Set_Districts', res.data.locations)
        })
        .catch((err) => { })
    }
  }
}
