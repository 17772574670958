import API from '../utils/API'

export default {
  getOffer (data) {
    return API({
      url: 'getOffer',
      data,
      method: 'POST',
      requestId: 'sectionOffers'
    })
  }
}
