<template>
  <div
    id="app"
    :dir="$t('dir')"
    :class="$t('dir') == 'ltr' ? 'en' : 'ar'"
  >
    <router-view />
  </div>
</template>

<script>
import axios from 'axios'
// import { useI18n } from 'vue-i18n'

export default {
  // setup () {
  //   const { t } = useI18n()
  // },
  data () {
    return {
      is_success: false,
      counter: 0
    }
  },
  created () {
    this.getIP()
  },
  methods: {
    getIP () {
      axios
        .get('https://jsonip.com/')
        .then((res) => {
          this.is_success = true
          localStorage.setItem('my_ip', res.data.ip)
        })
        .catch(() => {
          this.repeatApi()
        })
    },

    repeatApi () {
      if (this.counter < 3 && !this.is_success) {
        this.getIP()
        this.counter++
      }
    }
  }
}
</script>
