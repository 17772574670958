import API from '../utils/API'

export default {
  getCartList (data) {
    return API({
      url: 'getCartInfo',
      data,
      method: 'POST',
      requestId: 'getCartInfo'
    })
  },

  deleteFromCart (data) {
    return API({
      url: 'deleteCartItem',
      data,
      method: 'POST',
      requestId: 'deleteCartItem'
    })
  },

  addToCart (data) {
    return API({
      url: 'addToCart',
      data,
      method: 'POST',
      requestId: 'addToCart'
    })
  },

  generalData (data) {
    return API({
      url: 'general_data',
      data,
      method: 'POST',
      requestId: 'general_data'
    })
  },

  getPaymentData (data) {
    return API({
      url: 'paymentList',
      data,
      method: 'POST',
      requestId: 'paymentList'
    })
  },

  completePayment (data) {
    return API({
      url: 'placeOrder',
      data,
      method: 'POST',
      requestId: 'placeOrder'
    })
  },

  payWithWalletNumber (data) {
    return API({
      url: 'pay-with-wallet-number',
      data,
      method: 'POST',
      requestId: 'pay-with-wallet-number'
    })
  }
}
