export default {
  offers: {
    noOffers: 'There is no offers',
    discount: 'discount',
    priceAsc: 'Price : from Low to High ',
    priceDesc: 'Price : from High to Low ',
    reset: 'reset',
    map: 'map',
    openBranch: 'open branch',
    menu: 'menu image',
    noMenu: 'there is no menu images now',
    marchent: 'about marchent',
    offer: 'offer',
    offerContent: 'offer content',
    newOffers: 'new offers',
    bestOffers: 'best offers',
    browseOffers: 'browse Offers',
    watchBestOffers: 'watch best offers',
    backToShoping: 'Back To Shoping',
    addToCart: 'Added To Cart Successfully'
  }
}
