<template>
  <BaseModal
    v-if="openQRModal"
    @close="hideModalPopup()"
  >
    <qrcode-vue
      :value="value"
      :size="size"
      level="H"
    />
  </BaseModal>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import BaseModal from '../base-modal/Index.vue'

export default {
  name: 'QRCODE',
  components: {
    QrcodeVue,
    BaseModal
  },

  props: {
    value: {
      type: String
    },

    openQRModal: {
      type: Boolean
    }
  },

  data () {
    return {
      size: 200
    }
  },

  computed: {
    // QRSize () {
    //   if (window.innerWidth < 720) {
    //     this.size = 100
    //   } else {
    //     this.size = 300
    //   }
    //   return this.size
    // }
  },

  methods: {
    hideModalPopup () {
      this.$emit('closeQRModal', this.openQRModal)
    }
  }
}
</script>
