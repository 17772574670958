import singleOffer from '../../services/singleOffer'
import router from '@/router/index'

export default {
  namespaced: true,

  state: {
    offerDetails: {},
    max_coupons: [],
    type_price_id: '',
    loading: false
  },

  mutations: {
    Max_Coupons(state, data) {
      state.max_coupons = data.map((el) => {
        return el.type_max_coupons_per_user
      })
    },

    Get_Offer(state, data) {
      state.offerDetails = data
    },

    Set_Loading(state, data) {
      state.loading = data
    },

    Type_Price_Id(state, data) {
      data.map((el) => {
        state.type_price_id = el.type_price_id
      })
    }
  },

  actions: {
    /// Get getSingleOffer And Max Coupons
    getSingleOffer({ commit }, payload) {
      commit('Set_Loading', true)
      singleOffer
        .getOffer(payload)
        .then((res) => {
          commit('Set_Loading', false)
          commit('Get_Offer', res.data.offer)
          commit('Loading_OFFER')
          commit('Max_Coupons', res.data.offer.type_price)
          commit('Type_Price_Id', res.data.offer.type_price)
        })
        .catch((err) => {
          commit('Set_Loading', false)
          // router.push({ name: 'Home' })
        })
    }
  }
}
