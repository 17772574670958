export default [
  {
    path: '/profile',
    component: () => import(/* webpackChunkName: "profile" */ '../../views/Profile/Index.vue'),
    redirect: { name: 'my-account' },
    children: [
      {
        path: '/orders',
        name: 'orders',
        component: () => import(
          /* webpackChunkName: "MyOrders" */ '../../views/Profile/Orders/Index.vue'
        ),
        meta: { requireAuth: true },

        redirect: { name: 'my-orders' },
        children: [
          {
            path: 'my-orders',
            name: 'my-orders',
            component: () => import(
              /* webpackChunkName: "MyOrders" */ '../../views/Profile/Orders/MyOrders.vue'
            ),
            meta: { requireAuth: true }
          },

          {
            path: 'favorite-offers',
            name: 'favorite-offers',
            component: () => import(
              /* webpackChunkName: "FinishedOrders" */ '../../views/Profile/Orders/FinishedOrders.vue'
            ),
            meta: { requireAuth: true }
          },
          {
            path: 'discount-coupons',
            name: 'discount-coupons',
            component: () => import(
              /* webpackChunkName: "FinishedOrders" */ '../../views/Profile/Orders/FinishedOrders.vue'
            ),
            meta: { requireAuth: true }
          }
        ]
      },

      {
        path: '/settings',
        name: 'settings',
        component: () => import(
          /* webpackChunkName: "Settings" */ '../../views/Profile/Settings/Index.vue'
        ),
        meta: { requireAuth: true },

        redirect: { name: 'account-details' },
        children: [
          {
            path: 'account-details',
            name: 'account-details',
            component: () => import(
              /* webpackChunkName: "AccountDetails" */ '../../views/Profile/Settings/AccountDetails.vue'
            ),
            meta: { requireAuth: true }
          },

          {
            path: 'password',
            name: 'password',
            component: () => import(
              /* webpackChunkName: "Password" */ '../../views/Profile/Settings/Password.vue'
            ),
            meta: { requireAuth: true }
          },

          {
            path: 'shipping-operations',
            name: 'shipping-operations',
            component: () => import(
              /* webpackChunkName: "ShippingOperations" */ '../../views/Profile/Settings/ShippingOperations.vue'
            ),
            meta: { requireAuth: true }
          },

          {
            path: 'addresses',
            name: 'addresses',
            component: () => import(
              /* webpackChunkName: "Addresses" */ '../../views/Profile/Settings/Addresses.vue'
            ),
            meta: { requireAuth: true }
          }
        ]
      },

      {
        path: '/invite-your-friend',
        name: 'invite-your-friend',
        component: () => import(
          /* webpackChunkName: "InviteYourFriend" */ '../../views/Profile/InviteYourFriend.vue'
        ),
        meta: { requireAuth: true }
      },

      {
        path: 'cart',
        name: 'cart',
        component: () => import(/* webpackChunkName: "Cart" */ '../../views/Profile/Cart.vue'),
        meta: { isPublic: true }
      },

      {
        path: 'my-account',
        name: 'my-account',
        component: () => import(
          /* webpackChunkName: "MyAccount" */ '../../views/Profile/MyAccount.vue'
        ),
        meta: { requireAuth: true }
      }
    ]
  }
]
