<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 19"
    width="26"
    height="26"
  >
    <path
      id="Shape"
      d="M8.002,8.006 C5.796,8.006 4.002,6.212 4.002,4.006 C4.002,1.801 5.796,0.006 8.002,0.006 C10.208,0.006 12.002,1.801 12.002,4.006 C12.002,6.212 10.208,8.006 8.002,8.006 Z M8.002,2.006 C6.899,2.006 6.002,2.902 6.002,4.006 C6.002,5.109 6.899,6.006 8.002,6.006 C9.105,6.006 10.002,5.109 10.002,4.006 C10.002,2.902 9.105,2.006 8.002,2.006 Z"
    />
    <path
      id="Shape"
      d="M16.002,16.006 L0.002,16.006 L0.002,15.006 C0.002,11.473 3.292,9.006 8.002,9.006 C12.712,9.006 16.002,11.473 16.002,15.006 L16.002,16.006 Z M2.161,14.006 L13.844,14.006 C13.246,12.198 11.011,11.006 8.003,11.006 C4.995,11.006 2.759,12.198 2.161,14.006 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'AccountIcon'
}
</script>
