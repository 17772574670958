export default {
  account: {
    membership: 'gold',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id ante at dolor laoreet fermentum ac pharetra purus. Morbi eget nisi enim.',
    editAccount: 'Edit Account',
    startPoint: 'point 0',
    endPoint: '240',
    point1: '1000 نقطة',
    point2: 'reach point 3000',
    platinum: 'platinum',
    benefits: 'know all benefits',
    levels: 'all levels',
    saved: 'saved',
    egp: 'EGP',
    spend: 'spended',
    month: 'this month',
    time: 'all time',
    credit: 'your credit',
    text2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    addBalance: 'Add balance',
    preTransfer: 'previous transfer',
    yourPoints: 'yourPoints',
    yourMony: '1,450',
    text3: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    changePoints: 'change points',
    getPoints: 'get points',
    follow: 'follow us on facebook',
    title: 'account information',
    text4: 'you can change your information',
    list: 'List account',
    subscribe: 'Ready for save ? subscribe now',
    agree: 'I want to subscribe to get last updates from waffarha ',
    updateList: ' Update List',
    newAddress: 'add new address',
    addresses: 'Addresses',
    address: 'cairo, new cairo , el yasmin',
    editAddress: 'edit address',
    deleteAddress: 'delete address',
    shippingName: 'Lorem ipsum dolor sit amet',
    date: '10/11/2020',
    price: '30',
    currency: 'LE',
    ShippingOperations: 'Shipping operations',
    contactUs: 'contact us',
    allOrders: 'Orders',
    finishedOrders: 'finished orders',
    discountCoupons: 'discount coupons',
    purchaseCoupons: 'purchase coupons',
    howToUseCoupon: 'How to use Coupon',
    orderStep1: '1- Visit nearst branch to you ',
    orderStep2: '2- Ask customer service about waffrha ',
    orderStep3: 'ٍ3-show coupon or code to get the offer',
    purchaseCoupons: 'How to use purchase coupons',
    CartContents: 'See cart contents',
    totalCart: 'Total cart',
    useWallet: 'use wallet',
    credit: '200 EGP',
    credit2: '200 EGP',
    credit3: '200 EGP',
    credit4: '200 EGP',
    title2: 'The amount required',
    totalCartContent: 'total cart content',
    wallet: 'wallet',
    expenses: 'administrative expenses and tax',
    haseDisccount: 'has disccount',
    totalExpenses: ' total operation',
    totalMoney: '40 EGP',
    buyForFriend: 'buy for friend',
    Coupons: 'Coupons',
    moveToFavourite: 'move to favourite',
    useCoupons: 'use coupons',
    redeemCoupon: 'redeem coupon',
    addToCart: 'addToCart',
    emptyCart: 'Your Cart IS Empty',
    couponBtn: 'Verify',
    coupon: 'promo code',
    remove: 'remove',
    removeFormFav: 'Remove',
    addToFav: 'add to favourite',
    cancel: 'cancel',
    moveToCart: 'Move To Cart',
    fav: 'Favourite',
    totalRequiredAmount: 'Total required amount'
  }
}
