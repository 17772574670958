<template>
  <div class="base-checkbox">
    <svg class="checkbox-symbol">
      <symbol
        id="check"
        viewbox="0 0 12 10"
      >
        <polyline
          points="1.5 6 4.5 9 10.5 1"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </symbol>
    </svg>

    <div class="checkbox-container">
      <input
        :id="value + '-checkbox'"
        class="checkbox-input"
        type="checkbox"
        :value="value"
        :checked="isChecked"
      >
      <template v-if="isLabelExist">
        <label
          class="checkbox d-flex justify-content-start align-items-center"
          :for="value + '-checkbox'"
          @click="toggle"
        >
          <span
            :class="[
              isChecked ? 'is-checked' : '',
              isRadius ? 'radius-full' : 'radius-4',
            ]"
          >
            <svg
              width="20px"
              height="20px"
            >
              <use xlink:href="#check" />
            </svg>
          </span>

          <span
            v-if="isSpecialContent"
            class="neutral-4-color-text fs-14 p-x-8"
            :class="labelColorClass"
            v-html="label"
          />
          <span
            v-else
            class="neutral-4-color-text fs-14 p-x-8"
            :class="labelColorClass"
          >{{ label }}</span>
        </label>
      </template>
      <template v-else>
        <label
          class="checkbox d-flex justify-content-start align-items-center"
          :class="{ 'is-no-label': !isLabelExist }"
          :for="inputId"
          @click="toggle"
        >
          <span
            :class="[
              isChecked ? 'is-checked' : '',
              isRadius ? 'radius-full' : 'radius-4',
            ]"
          >
            <svg
              width="20px"
              height="20px"
            >
              <use xlink:href="#check" />
            </svg>
          </span>
        </label>
      </template>
    </div>

    <div
      v-if="hasError"
      class="m-t-6"
    >
      <p class="wrong-data fs-14">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },

    isLabelExist: {
      type: Boolean,
      required: false,
      default: true
    },

    inputId: {
      type: [String, Number],
      required: true
    },

    value: {
      type: [Boolean, String, Number],
      required: true
    },

    hasError: {
      type: Boolean,
      required: false,
      default: false
    },

    message: {
      required: false,
      type: String,
      default: null
    },

    isSpecialContent: {
      type: Boolean,
      required: false,
      default: false
    },

    isChecked: {
      type: Boolean,
      required: false,
      default: false
    },

    isRadius: {
      type: Boolean,
      required: false,
      default: false
    },

    labelColorClass: {
      type: String,
      required: false,
      default: 'light-gray-color-text'
    }
  },

  data () {
    return {
      key: 1
    }
  },

  methods: {
    toggle () {
      this.key += 1
      this.$emit('toggle', this.inputId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './base-checkbox';
</style>
