export default {
  offers: {
    noOffers: 'لا يوجد منتجات',
    discount: 'خصم',
    priceAsc: 'من الاقل للأعلي',
    priceDesc: 'من الاعلي للأقل',
    reset: ' إعادة تعيين',
    map: 'الخريطة',
    openBranch: 'الفروع المتاحة',
    menu: 'المنيو مصورة',
    noMenu: 'لا يوجد مينيو مصورة',
    marchent: 'عن التاجر',
    offer: 'عرض',
    offerContent: 'محتويات العرض',
    newOffers: 'العروض الجديدة',
    bestOffers: 'اقوى العروض',
    browseOffers: 'تصفح العروض',
    watchBestOffers: 'شاهد العروض المميزة',
    backToShoping: 'عودة للتسوق',
    cardHolder: 'Cart Holder Name',
    addToCart: 'تم أضافة العرض لعربة التسوق بنجاح'
  }
}
