import Cart from '../../services/cart'
import { useToast } from 'vue-toastification'
const toast = useToast()


export default {
  namespaced: true,

  state: {
    lists: [],
    cartInfo: [],
    cartQuantity: '',
    isPromoCodeTrue: false,
    buyAsAGift: false,
    cartInfoLength: '',
    loading: false,
    isLoadVerifyPromoCode: false
  },

  mutations: {
    Get_Cart_List(state, data) {
      state.lists = data
    },

    Empty_Cart_List(state) {
      state.lists = []
      state.cartInfo = []
    },

    Get_User_Cart_Info(state, data) {
      state.cartInfo = data
      state.cartInfoLength = data.length
    },

    Set_Cart_Quantity(state, data) {
      state.cartQuantity = data
    },

    Verify_Promo_Code(state, data) {
      state.isPromoCodeTrue = data
    },

    Buy_For_Friend(state, data) {
      state.buyAsAGift = data
    },

    Set_Loading(state, data) {
      state.loading = data
    },

    Load_Verify_Promo_Code(state, data) {
      state.isLoadVerifyPromoCode = data
    }
  },

  getters: {
    CartQuantity(state) {
      return state.cartQuantity
    }
  },

  actions: {
    // Verify Promo Code
    verifyPromoCode({ commit, state }, payload) {
      commit('Load_Verify_Promo_Code', true)
      Cart.getCartList(payload)
        .then((res) => {
          commit('Load_Verify_Promo_Code', false)
          commit('Verify_Promo_Code', res.data.is_promo_code)
          commit('Get_User_Cart_Info', res.data.cart_info)
          toast.success(res.data.message)
        })
        .catch((err) => {
          commit('Load_Verify_Promo_Code', false)
        })
    },

    // Get All Carts
    getAllCarts({ commit }, payload) {
      // commit('Set_Loading', true)
      Cart.getCartList(payload)
        .then((res) => {
          // commit('Set_Loading', false)
          commit('Get_Cart_List', res.data.offers)
          commit('Get_User_Cart_Info', res.data.cart_info)
          commit('Verify_Promo_Code', res.data.is_promo_code)
          commit('Buy_For_Friend', res.data.buy_for_friend_feature)
        })
        .catch((err) => {
          // commit('Set_Loading', false)
          commit('Empty_Cart_List')
        })

    },

    payWithWalletInfo({ commit }, payload) {
      Cart.getCartList(payload)
        .then((res) => {
          commit('Get_Cart_List', res.data.offers)
          commit('Get_User_Cart_Info', res.data.cart_info)
        })
        .catch((err) => {
        })

    },


    // Add To Cart
    addToCart({ dispatch }, payload) {
      Cart.addToCart(payload)
        .then((res) => {
          dispatch('getGeneralData')
        })
        .catch((err) => {
        })
    },

    // Remove Cart
    removeFromCart({ dispatch }, payload) {
      Cart.deleteFromCart(payload)
        .then((res) => {
          dispatch('getGeneralData')
          toast.success(res.data.message)
        })
        .catch((err) => { })
    },

    // Get General Data
    // Get  Cart Quantity
    getGeneralData({ commit }) {
      const payload = {
        app_version: '',
        store: '',
        device_token: localStorage.getItem('my_ip')
      }
      Cart.generalData(payload)
        .then((res) => {
          commit('Set_Cart_Quantity', res.data.cart_quantity)
        })
        .catch((err) => { })
    }
  }
}
