export default {
  forms: {
    noAccount: 'Dont have account ?',
    haveAccount: 'Already have Account',
    loginText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    registerText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    newAccount: 'New account',
    welcome: 'Start save now !',
    login: 'Login',
    confirmCode: 'confirm',
    reSend: 'send agian',
    edit: 'edit',
    subTitle: 'Enter activation code',
    enterName: 'Enter your name',
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    password: 'Password',
    email: 'Email',
    enterBirthDate: 'Enter birth date',
    birthDate: 'birthDate',
    gender: 'gender',
    city: 'City',
    chooseCity: 'All Cities',
    chooseDistrict: 'choose district',
    allFields: 'choose field',
    prices: 'prices',
    districts: 'Districts',
    rememberMe: 'remember me',
    forgotPassword: 'forgot password ?',
    forgotPasswordTitle: 'Enter phone number',
    personalInfo: 'Personal Information',
    approve: 'I agree to the usage instructions and privacy statement',
    oldPassword: ' old password',
    newPassword: 'new password',
    confirmPassword: 'Confirm password',
    resetPassword: 'Reset password',
    resetMethod: 'Enter email or password',
    continue: 'Continue',
    continuePayment: 'Continue Payment',
    enterPhone: 'Enter your phone',
    enterPassword: 'Enter password',
    enterEmail: 'Enter valid email',
    save: 'Save',
    resetPassText: 'you can change your information',
    message: 'more details',
    contact: 'contact information',
    date: '26 adly mansour , dowen town , 9 am to 10 pm',
    social: ' social contact',
    address: '26 adly mansour street , dowen towwe',
    errorMessage: 'password not identical',
    regExpressionPasswordValidation:
      'password should contain numbers and letters ',
    emptyPasswordValidation: 'empty field',
    newPasswordExactConfirmation:
      'new & confirmation passwords should be exact',
    confirmPasswordExactConfirmation:
      'new & confirmation passwords should be exact',
    changePassword: 'change password',
    mailLang: 'Mail list language',
    smsLang: 'Sms Lang',
    validValue: 'Enter a valid value',
    validPhone: 'Enter a valid phone',
    validPassword: 'Enter a valid password',
    validEmail: 'Enter a valid email',
    user_birthday: 'birthday',
    district: 'district',
    name: 'name',
    ConfirmModalText: 'Remove offer and move it to favourit',
    choosePaymentMethod: 'Complete Payment',
    bankCard: 'Bank Cards',
    userName: 'User placeHolder Name',
    cardNumber: 'Card Number',
    cardEndDate: 'Card End Date',
    cvv: 'CVV',
    fawry: 'Fawry',
    aman: 'Aman',
    premiumcard: 'Premium Card',
    valu: 'ValU',
    walletPayment: 'Wallet Payment',
    chooseBank: 'Choose Bank',
    walletNumber: 'Wallet Phone Number',
    months: 'month',
    years: 'year',
    passwordTerms: '- Should contain characters and numbers from 0-9'
  }
}
