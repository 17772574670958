import API from "../utils/API";

export default {
    getCities(data) {
        return API({
            url: "cities",
            data,
            method: "POST",
            requestId: "cities",
        });
    },

    getDistricts(data) {
        return API({
            url: "locationsByCityId",
            data,
            method: "POST",
            requestId: "districts",
        });
    },
};
