import { createStore } from "vuex"
import Globals from './globals'
import Loader from './loader'
import locations from './locations'
import locale from './locale'
import footer from './footer'
import search from './search'
import cart from './cart'
import offers from './offers'

import createPersistedState from 'vuex-persistedstate'

const persistedState = createPersistedState({
  key: 'vuejs-vue-i18n',
  paths: ['locale']
})


export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Globals,
    Loader,
    locations,
    locale,
    footer,
    search,
    cart,
    offers
  },
  plugins: [
    createPersistedState({
      paths: ['Globals', 'locations'],
    }),
  ],
})
