import API from '../utils/API'

export default {
  getPrivacyPolicy () {
    return API({
      url: 'privacyPolicy',
      method: 'GET',
      requestId: 'privacyPolicy'
    })
  },

  usePolicy () {
    return API({
      url: 'usePolicy',
      method: 'GET',
      requestId: 'usePolicy'
    })
  },

  aboutUs () {
    return API({
      url: 'aboutUs',
      method: 'GET',
      requestId: 'aboutUs'
    })
  },

  howToBuy () {
    return API({
      url: 'howToBuy',
      method: 'GET',
      requestId: 'howToBuy'
    })
  },

  howToPay () {
    return API({
      url: 'howToPay',
      method: 'GET',
      requestId: 'howToPay'
    })
  },

  contactUs (data) {
    return API({
      url: 'contactUs',
      method: 'POST',
      requestId: 'contactUs'
    })
  }
}
