<template>
  <div
    class="base-modal p-30"
    @click.self="close"
  >
    <div class="modal-content white-color-bg radius-16 p-y-18 p-x-16">
      <!-- will use later -->
      <div
        class="modal-header d-flex justify-content-between p-b-12 align-items-center"
      >
        <!-- <h3 class="semi-bold-font fs-18">
          {{ title }}
        </h3> -->
        <div
          class="close-icon radius-full cursor-pointer"
          @click="close"
        >
          <CloseIcon />
        </div>
      </div>

      <div class="modal-body p-y-10 text-center">
        <slot />
      </div>

      <div
        v-if="hasActions"
        class="modal-footer d-flex justify-content-center align-items-center"
      >
        <BaseButton
          isMedium
          text="Cancel"
          class="m-r-14"
          fixedWidth="100px"
          @click.native="close"
        />
        <BaseButton
          isMedium
          text="Submit"
          fixedWidth="100px"
          @click.native="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/components/icons/CloseIcon'
import BaseButton from '../base-button/Index.vue'

export default {
  name: 'BaseModal',
  components: { BaseButton, CloseIcon },
  props: {
    title: {
      type: String,
      required: false
    },

    // message: {
    //   type: String,
    //   required: true
    // },

    hasActions: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },

    submit () {
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './base-modal';
</style>
