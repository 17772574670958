export default {
  forms: {
    noAccount: 'ليس لديك حساب؟',
    haveAccount: 'لديك حساب بالفعل؟',
    loginText:
      'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحةما سيلهي القارئ.',
    registerText:
      'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحةما سيلهي القارئ.',

    newAccount: 'تسجيل حساب ',
    welcome: 'ابدأ التوفير الأن !',
    login: ' تسجيل الدخول',
    confirmCode: 'تأكيد التفعيل',
    reSend: 'اعادة ارسال',
    edit: 'تعديل',
    subTitle: 'قم بادخال كود التفعيل الذي تم ارساله الي الهاتف',
    enterName: 'ادخل الأسم',
    firstName: 'الإسم الأول',
    lastName: 'الإسم الثاني',
    phone: 'رقم الموبايل',
    password: 'كلمة المرور',
    email: 'البريد الالكتروني',
    birthDate: 'تاريخ الميلاد',
    enterBirthDate: 'ادخل تاريخ الميلاد',
    gender: 'النوع',
    city: 'المدينة',
    chooseCity: 'اختر المدينة',
    chooseDistrict: 'اختر المنطقة',
    allFields: 'جميع الأقسام',
    prices: 'جميع الاسعار',
    districts: 'المنطقة',
    rememberMe: 'تذكرني',
    forgotPassword: ' نسيت كلمة المرور؟',
    personalInfo: ' المعلومات الشخصية',
    approve: 'اوافق علي شروط المستخدم والخصوصية',
    resetPassText:
      '  تقدر تغير معلوماتك ، معلوماتك بنستخدمها فى التسجيل على وفرها ، شحن وتوصيل المنتجات.',
    oldPassword: 'كلمة المرور القديمة',
    resetPassword: '  اعادة تعيين كلمه مرور جديدة',
    forgotPasswordTitle: 'قم بادخال رقم هاتفك',
    resetMethod: 'البريد الالكتروني او رقم الموبايل',
    continue: 'إستمرار',
    continuePayment: 'اتمام الدفع',
    enterPhone: 'ادخل رقم الموبايل',
    enterEmail: 'ادخل البريد الألكتروني',
    enterPassword: 'ادخل كلمة المرور',
    newPassword: 'كلمة المرور الجديدة',
    oldPassword: 'كلمة المرور القديمة',
    confirmPassword: 'تأكيد كلمة المرور',
    resetPassword: '  اعادة تعيين كلمه مرور جديدة',
    willSendSms: 'سنرسل اليك رسالة تاكيد',
    save: 'حفظ التغيرات',
    message: 'تفاصيل اكثر',
    contact: 'معلومات الإتصال',
    date: 'يوميا من الساعه 9 صباحا حتى الساعه 12 بعد منتصفالليل',
    social: 'التواصل الإجتماعي',
    address: ' 26 شارع عدلي منصور ',
    errorMessage: 'كلمة المرور غير متطابقة',
    regExpressionPasswordValidation:
      'يجب ان تحتوى كلمة المرور القديمة علي حروف وارقام',
    emptyPasswordValidation: 'هذا الحقل فارغ',
    newPasswordExactConfirmation:
      'يجب ان تكون كلمة المرور وتاكيد كلمة المرور متماثلين',
    confirmPasswordExactConfirmation:
      'يجب ان تكون كلمة المرور وتاكيد كلمة المرور متماثلين',
    changePassword: 'تغيير كلمة المرور',
    mailLang: 'لغة القائمة البريدية',
    validValue: 'ادخل قيمة صحيحة',
    smsLang: 'لغة الرسائل النصية',
    validPhone: 'ادخل رقم صحيح',
    validPassword: 'ادخل كلمة مرور صحيحة',
    validEmail: 'ادخل بريد ألكتروني صحيح',
    user_birthday: 'تاريخ الميلاد',
    district: 'المنطقة',
    name: 'الإسم',
    ConfirmModalText: 'هل تريد حذف العرض ونقله للعروض المفضلة ؟',
    choosePaymentMethod: 'اختر طريقة الدفع',
    bankCard: 'البطاقات البنكية',
    userName: 'اسم صاحب الكارت',
    cardNumber: 'رقم الكارت',
    cardEndDate: 'تاريخ الانتهاء',
    cvv: 'CVV',
    fawry: 'فوري',
    aman: 'أمان',
    premiumcard: 'بريميوم كارد',
    valu: 'فاليو',
    walletPayment: 'الدفع بالمحافظ الإلكترونية',
    chooseBank: 'اختر البنك',
    walletNumber: 'ادخل رقم المحفظة',
    months: 'الشهر',
    years: 'السنة',
    cardHolder: 'اسم صاحب الكارت',
    passwordTerms: '- يجب أن يحتوي علي حروف وأرقام من 0-9'
  }
}
