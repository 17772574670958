<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      id="Path"
      d="M9,6 L9,0 L5,0 C4.666,0 4.353,0.168 4.168,0.446 L0.465,6 L9,6 Z"
    />
    <path
      id="Path"
      d="M19.535,6 L15.832,0.446 C15.646,0.168 15.334,0 15,0 L11,0 L11,6 L19.535,6 Z"
    />
    <path
      id="Shape"
      d="M0,19 C0,19.552 0.447,20 1,20 L19,20 C19.553,20 20,19.552 20,19 L20,8 L0,8 L0,19 Z M11,14 L17,14 L17,17 L11,17 L11,14 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OrdersIcon'
}
</script>
