export default {
  namespaced: true,
  state: {
    lang: 'ar'
  },

  mutations: {
    setLocale (state, locale) {
      state.lang = locale
      localStorage.setItem('lang', locale)
    }
  }
}
