<template>
  <div>
    <div
      class="order-card d-flex justify-content-between align-items-center radius-12 p-12 m-b-16"
    >
      <div class="order-info d-flex justify-content-start align-items-center">
        <div class="img-container radius-6 m-r-10">
          <img
            class="cursor-pointer"
            :src="purchase.offer.offer_image"
            :alt="purchase.offer.offer_name"
            @click="sendOfferId(purchase.offer.offer_id)"
          >
        </div>

        <div class="content">
          <h4
            class="bold-font fs-16 black-color-text title cursor-pointer"
            @click="sendOfferId(purchase.offer.offer_id)"
          >
            {{ purchase.offer.offer_name }}
          </h4>
          <p class="fs-14 light-gray-color-text m-y-10 description">
            {{ purchase.type_price.type_price_name }}
          </p>
          <div
            class="d-flex justify-content-start align-items-center gap-24 num-of-coupons"
          >
            <span class="black-color-text fs-14 medium-font">
              {{ purchase.purchase_no_coupons }}
            </span>
            <span class="black-color-text fs-14 medium-font main-color-text">
              {{ purchase.order_num }}
            </span>
          </div>
        </div>
      </div>

      <div class="order-action">
        <div
          class="badgs d-flex justify-content-end align-items-center gap-10 m-b-20"
        >
          <div class="black-color-bg p-y-4 p-x-8 radius-4">
            <span
              class="fs-12 medium-font white-color-text"
            >{{ purchase.price }}
            </span>
          </div>

          <div
            :style="{ background: `${purchase.offer.coupon_type.color}33` }"
            class="p-y-4 p-x-8 radius-4"
          >
            <span class="fs-12 medium-font font-weight-600 main-color-text">
              {{ purchase.offer.coupon_type.value }}
            </span>
          </div>
        </div>

        <div
          class="actions d-flex justify-content-end align-items-center gap-10"
        >
          <div
            v-if="purchase.purchase_status.pur_status_id === 1"
            class="actions d-flex justify-content-end align-items-center gap-10"
          >
            <BaseButton
              :text="$t('home.showOfferCoupon')"
              fixedWidth="140px"
              :is-medium="true"
              colorClass="white-color-text"
              bgColorClass="main-color-bg"
              @click.native="showCode(purchase.purchase_id)"
            />

            <BaseButton
              fixedWidth="140px"
              :is-medium="true"
              bgColorClass="white-color-bg"
              colorClass="black-color-text"
              :text="$t('home.refundCoupon')"
              :style="{ border: '1px solid #ccc' }"
              @click.native="refundCoupon(orderId, purchase.purchase_id)"
            />
          </div>

          <div
            v-else
            class="d-flex justify-content-center align-items-center yellow-color-bg medium-font radius-24 fawry-code"
          >
            <p
              id="code"
              v-html="purchase.fawry_code_web"
            />

            <div
              v-clipboard:copy="purchase.fawry_code_web"
              v-clipboard:success="onCopy"
              type="button"
            >
              <CopyIcon class="copy-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Show Code Modal-->
    <ShowCodemodal
      :openModal="openModal"
      :couponData="couponData"
      :coupon_status="coupon_status"
      :expire_date="expire_date"
      @closeModal="closeModal"
    />

    <!--Refund Code Modal -->
    <RefundCodemodal
      :orderId="orderId"
      :purchaseId="purchase.purchase_id"
      :openRefundModal="openRefundModal"
      :couponRefundData="couponRefundData"
      :refund_payment_types="refund_payment_types"
      @closeRefundModal="closeRefundModal"
    />
  </div>
</template>

<script>
import ShowCodemodal from './showCodeModal.vue'
import RefundCodemodal from './refundCouponModal.vue'
import OrderCouponData from '../../../services/profile'
import OrderCouponRefundData from '../../../services/profile'
import CopyIcon from '../../../components/icons/CopyIcon.vue'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  name: 'BaseOrderCard',
  components: { ShowCodemodal, RefundCodemodal, CopyIcon },
  props: {
    purchase: {
      type: Object,
      required: false,
      default: () => ({})
    },

    orderId: {
      type: Number
    }
  },

  data () {
    return {
      openModal: false,
      couponData: {},
      coupon_status: '',
      expire_date: '',
      openRefundModal: false,
      couponRefundData: {},
      refund_payment_types: []
    }
  },

  methods: {
    showCode (id) {
      this.openModal = true
      const payload = {
        token: this.getUserToken,
        app_version: '',
        store: '',
        purchase_id: id
      }
      OrderCouponData.orderCouponData(payload)
        .then((res) => {
          this.couponData = res.data
          res.data.coupons.map((item) => {
            this.coupon_status = item.coupon_status.pur_status_name
            this.expire_date = item.expire_date
          })
        })
        .catch((err) => {})
    },

    closeModal () {
      this.openModal = false
    },

    refundCoupon (orderId) {
      const payload = {
        token: this.getUserToken,
        app_version: '',
        store: '',
        order_id: orderId
      }

      OrderCouponRefundData.orderCouponRefundData(payload)
        .then((res) => {
          const refundCouponStatus = res.data.data.flag
          if (!refundCouponStatus) {
            toast.info(res.data.message, { timeout: false })
          } else {
            this.couponRefundData = res.data.data
            this.openRefundModal = true
            this.refund_payment_types =
              this.couponRefundData.refund_payment_types.map((el) => {
                return {
                  id: el.id,
                  name: el.value,
                  value: el.id
                }
              })
          }
        })
        .catch((err) => {})
    },

    closeRefundModal () {
      this.openRefundModal = false
    },

    onCopy () {
      toast.success(this.$t('home.success'))
    },

    sendOfferId () {
      this.$emit(
        'goToOffer',
        this.purchase.offer.offer_id,
        this.purchase.offer.offer_name
      )
    }
  }
}
</script>
<style lang="scss" scoped>
@import './base-order-card.scss';
</style>
