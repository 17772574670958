<template>
  <div class="base-text-input">
    <div class="d-flex justify-content-between align-items-center">
      <label
        :for="inputId"
        class="regular-font"
      >{{ label }}</label>
      <span
        v-if="message && errorPosition == 'top'"
        class="error-message regular-font"
      >{{ message }}</span>
    </div>
    <input
      :id="inputId"
      :type="type"
      :maxlength="maxlength"
      :name="name"
      :value="value"
      :placeholder="placeholder"
      :readonly="isReadOnly"
      :disabled="isDisabled"
      class="medium-font m-t-10"
      :class="[
        hasError ? 'wrong-data' : '',
        !!value && !hasError ? 'valid-data' : '',
        isRadius ? 'radius-26' : 'radius-8',
      ]"
      autocomplete="off"
      @input="input($event)"
      @keypress="isNumber($event)"
      @keyup.enter="submit"
    >
    <span
      v-if="message && errorPosition == 'bottom'"
      class="error-message regular-font"
    >{{ message }}</span>
  </div>
</template>

<script>
import trimSpaces from '../../../utils/trimSpaces'

export default {
  name: 'BaseTextInput',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: false,
      default: null
    },

    inputId: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: false
    },

    type: {
      type: String,
      required: false,
      default: 'text'
    },

    value: {
      type: [String, Number],
      required: false,
      default: null
    },

    maxlength: {
      type: [String, Number],
      required: false,
      default: null
    },

    hasError: {
      type: Boolean,
      required: false,
      default: true
    },

    message: {
      type: String,
      required: false,
      default: null
    },

    isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },

    placeholder: {
      type: String,
      required: false,
      default: ''
    },

    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },

    isRadius: {
      type: Boolean,
      required: false,
      default: false
    },

    errorPosition: {
      type: String,
      required: false,
      default: 'top'
    }
  },
  methods: {
    input (event) {
      this.$emit('update-value', {
        value: event.target.value,
        id: this.inputId
      })

      this.$emit('update:value', event.target.value)
      this.$emit('reset')
    },

    isNumber (event) {
      this.$emit('isNumber', event)
    },

    submit () {
      this.$emit('submit')
    }

    // trimSpacesFromValue (value) {
    //   return trimSpaces(value)
    // }
  }
}
</script>

<style lang="scss" scoped>
@import './base-text-input';
</style>
