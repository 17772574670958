import axios from 'axios'
import Cookies from 'js-cookie'
import store from '../store'
import toFormData from './toFormData'
import generateSecurityKey from './generateSecurityKey'
import i18n from '../lang'

const qs = require('qs')
const cancelTokens = {}
let numberOfPendingRequests = 0
const TOKEN_KEY = 'WAFFARHA'
const API_URL = process.env.VUE_APP_API_URL

axios.interceptors.request.use(
  (params) => {
    numberOfPendingRequests = numberOfPendingRequests + 1

    const requests = [
      ...store.state.Loader.pendingRequestsIds,
      params.requestId
    ]

    store.dispatch('Loader/setPendingRequestsIds', requests, {
      root: true
    })

    if (params.showLoader) {
      store.dispatch('Loader/setIsLoading', true, { root: true })
    }

    return params
  },

  (errors) => {
    const requests = store.state.Loader.pendingRequestsIds.filter((request) => {
      return request !== errors.response.config.requestId
    })

    store.dispatch('Loader/setPendingRequestsIds', requests, {
      root: true
    })

    numberOfPendingRequests = numberOfPendingRequests - 1
  }
)

axios.interceptors.response.use(
  (params) => {
    numberOfPendingRequests -= 1

    if (numberOfPendingRequests === 0) {
      store.dispatch('Loader/setIsLoading', false, { root: true })
    }

    cancelTokens[params.config.requestId] = null

    const requests = store.state.Loader.pendingRequestsIds.filter(
      (request) => request !== params.config.requestId
    )

    store.dispatch('Loader/setPendingRequestsIds', requests, {
      root: true
    })

    return Promise.resolve(params)
  },

  (errors) => {
    numberOfPendingRequests -= 1

    if (numberOfPendingRequests === 0) {
      store.dispatch('Loader/setIsLoading', false, { root: true })
    }

    if (errors.response && errors.response.status) {
      const requests = store.state.Loader.pendingRequestsIds.filter(
        (request) => request !== errors.response.config.requestId
      )

      store.dispatch('Loader/setPendingRequestsIds', requests, {
        root: true
      })

      // store.dispatch('ResponseHandling/handleError', { status: errors.response.status, request: errors.response.config.requestId }, { root: true })
    }

    return Promise.reject(errors)
  }
)
function API({
  url,
  data = {},
  method,
  params = {},
  disableLoader = false,
  requestId,
  isFullURL = false
}) {

  // const lang = i18n.locale ? i18n.locale : 'ar'
  const lang = i18n.locale ? i18n.locale : 'ar'

  console.log('lang', lang)

  data['lang'] = lang

  const securityKey = generateSecurityKey(data)
  data['security_key'] = securityKey

  // make form data global
  data = toFormData(data)

  const token = Cookies.get(TOKEN_KEY)

  if (cancelTokens[requestId]) {
    cancelTokens[requestId].cancel('')
  }

  const { CancelToken } = axios
  cancelTokens[requestId] = CancelToken.source()

  axios.defaults.showLoader = !disableLoader
  axios.defaults.requestId = requestId

  if (method.toLowerCase() === 'get') {
    ; (params['lang'] = lang), (params['security_key'] = securityKey)
  }
  const requestParams = params

  const headers = {
    ...(token && { Authorization: `Bearer ${token}` }),
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    // 'X-CSRF-TOKEN': (document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').getAttribute('content') : undefined),
  }

  if (params && params.file) {
    headers['Content-Type'] = 'multipart/form-data'
  }

  if (params && method.toLowerCase() === 'get') {
    Object.keys(requestParams).forEach((key) => (requestParams[key] === '' && key !== 'q' ? delete requestParams[key] : null)
    )
  }

  return axios({
    method,
    url: isFullURL ? url : `${API_URL}${url}`,
    data,
    params: requestParams,
    paramsSerializer(parameters) {
      return qs.stringify(parameters, { encode: false })
    },
    cancelToken: cancelTokens[requestId].token,
    headers
  })
}

export default API
