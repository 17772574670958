export default function toFormData (obj) {
  const formBody = new FormData()
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((val, i) => {
        formBody.append(`${key}[${i}]`, val)
      })
    } else formBody.append(key, obj[key])
  })
  return formBody
}

// obj = elly e7na mesh 3arfeno
//
