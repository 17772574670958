export default [
    {
        path: "/who-we-are",
        name: "who-we-are",
        component: () =>
            import(
                /* webpackChunkName: "Login" */ "../../views/static-pages/who-we-are.vue"
            ),
        meta: { isPublic: true },
    },
    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () =>
            import(
                /* webpackChunkName: "Login" */ "../../views/static-pages/privacy-policy.vue"
            ),
        meta: { isPublic: true },
    },
    {
        path: "/contact-us",
        name: "contact-us",
        component: () =>
            import(
                /* webpackChunkName: "Login" */ "../../views/static-pages/contact-us.vue"
            ),
        meta: { isPublic: true },
    },

    {
        path: "/privacy-use",
        name: "privacy-use",
        component: () =>
            import(
                /* webpackChunkName: "Login" */ "../../views/static-pages/privacy-use.vue"
            ),
        meta: { isPublic: true },
    },

    {
        path: "/how-to-pay",
        name: "how-to-pay",
        component: () =>
            import(
                /* webpackChunkName: "Login" */ "../../views/static-pages/how-to-pay.vue"
            ),
        meta: { isPublic: true },
    },
    {
        path: "/how-to-buy",
        name: "how-to-buy",
        component: () =>
            import(
                /* webpackChunkName: "Login" */ "../../views/static-pages/how-to-buy.vue"
            ),
        meta: { isPublic: true },
    },
    {
        path: "/suggestion",
        name: "suggestion",
        component: () =>
            import(
                /* webpackChunkName: "Login" */ "../../views/static-pages/suggestion.vue"
            ),
        meta: { isPublic: true },
    },
];
